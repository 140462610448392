
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_939U7nAreEhYMeta } from "/vercel/path0/pages/ats/candidats/[id].vue?macro=true";
import { default as indexv3Tbft2a6EMeta } from "/vercel/path0/pages/ats/candidats/index.vue?macro=true";
import { default as candidatures_45spontaneeshtqfDx4V78Meta } from "/vercel/path0/pages/ats/candidatures-spontanees.vue?macro=true";
import { default as collaborateurs50QhNYpaOVMeta } from "/vercel/path0/pages/ats/employeur/collaborateurs.vue?macro=true";
import { default as localisationsaLupqay8NRMeta } from "/vercel/path0/pages/ats/employeur/localisations.vue?macro=true";
import { default as modelesKFDdq9LpKfMeta } from "/vercel/path0/pages/ats/employeur/modeles.vue?macro=true";
import { default as organisationOrykJFRTq0Meta } from "/vercel/path0/pages/ats/employeur/organisation.vue?macro=true";
import { default as page_45emploi5nD8SxKNeDMeta } from "/vercel/path0/pages/ats/employeur/page-emploi.vue?macro=true";
import { default as presentateursWfh0OflnbNMeta } from "/vercel/path0/pages/ats/employeur/presentateurs.vue?macro=true";
import { default as rh1WqcXZwlOtMeta } from "/vercel/path0/pages/ats/employeur/rh.vue?macro=true";
import { default as indexGI2VqlXZIeMeta } from "/vercel/path0/pages/ats/index.vue?macro=true";
import { default as indexRtPCLYamA1Meta } from "/vercel/path0/pages/ats/offres/[id]/index.vue?macro=true";
import { default as modification4XkgpcMAhNMeta } from "/vercel/path0/pages/ats/offres/[id]/modification.vue?macro=true";
import { default as indexxQAvVcSG5aMeta } from "/vercel/path0/pages/ats/offres/index.vue?macro=true";
import { default as indexQntFY4BMcEMeta } from "/vercel/path0/pages/ats/statistiques/index.vue?macro=true";
import { default as indexLoM8BeFJ65Meta } from "/vercel/path0/pages/career/[slug]/index.vue?macro=true";
import { default as _91job_93IzLOehWAhVMeta } from "/vercel/path0/pages/career/[slug]/jobs/[job].vue?macro=true";
import { default as indexOmFKZXSFUSMeta } from "/vercel/path0/pages/career/[slug]/jobs/index.vue?macro=true";
import { default as conditions_45generalesShNoDLnComMeta } from "/vercel/path0/pages/conditions-generales.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as _91slug_93KMd6cPdYkvMeta } from "/vercel/path0/pages/employeurs/[slug].vue?macro=true";
import { default as indexMBjjnLUZjDMeta } from "/vercel/path0/pages/employeurs/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexEk4dtrDptRMeta } from "/vercel/path0/pages/jobs/[slug]/index.vue?macro=true";
import { default as vignettemGfW2C4O2BMeta } from "/vercel/path0/pages/jobs/[slug]/vignette.vue?macro=true";
import { default as index4iqCgT2ECDMeta } from "/vercel/path0/pages/jobs/index.vue?macro=true";
import { default as maintenanceLjk4bqrWMWMeta } from "/vercel/path0/pages/maintenance.vue?macro=true";
import { default as mentions_45legalesaI7UrXH82KMeta } from "/vercel/path0/pages/mentions-legales.vue?macro=true";
import { default as alertes_45emploijRPIfzCOLcMeta } from "/vercel/path0/pages/mon-profil/alertes-emploi.vue?macro=true";
import { default as candidaturesogiKRmDL03Meta } from "/vercel/path0/pages/mon-profil/candidatures.vue?macro=true";
import { default as compteSO9ROrg1XSMeta } from "/vercel/path0/pages/mon-profil/compte.vue?macro=true";
import { default as favoris7F0ojKUlpiMeta } from "/vercel/path0/pages/mon-profil/favoris.vue?macro=true";
import { default as indexvrfasLhe06Meta } from "/vercel/path0/pages/mon-profil/index.vue?macro=true";
import { default as informationsbm8gBvMltfMeta } from "/vercel/path0/pages/mon-profil/informations.vue?macro=true";
import { default as notificationsSauKZZuWeNMeta } from "/vercel/path0/pages/mon-profil/notifications.vue?macro=true";
import { default as signaturegLeMEO32HwMeta } from "/vercel/path0/pages/mon-profil/signature.vue?macro=true";
import { default as nos_45partenairesJWc0c6htMKMeta } from "/vercel/path0/pages/nos-partenaires.vue?macro=true";
import { default as nos_45services1Hf5eHhwHuMeta } from "/vercel/path0/pages/nos-services.vue?macro=true";
import { default as politique_45de_45donneest4emK3bLgcMeta } from "/vercel/path0/pages/politique-de-donnees.vue?macro=true";
import { default as presentation_45atsQUoZQnDpxBMeta } from "/vercel/path0/pages/presentation-ats.vue?macro=true";
export default [
  {
    name: "ats-candidats-id",
    path: "/ats/candidats/:id()",
    meta: _91id_939U7nAreEhYMeta || {},
    component: () => import("/vercel/path0/pages/ats/candidats/[id].vue")
  },
  {
    name: "ats-candidats",
    path: "/ats/candidats",
    meta: indexv3Tbft2a6EMeta || {},
    component: () => import("/vercel/path0/pages/ats/candidats/index.vue")
  },
  {
    name: "ats-candidatures-spontanees",
    path: "/ats/candidatures-spontanees",
    meta: candidatures_45spontaneeshtqfDx4V78Meta || {},
    component: () => import("/vercel/path0/pages/ats/candidatures-spontanees.vue")
  },
  {
    name: "ats-employeur-collaborateurs",
    path: "/ats/employeur/collaborateurs",
    meta: collaborateurs50QhNYpaOVMeta || {},
    component: () => import("/vercel/path0/pages/ats/employeur/collaborateurs.vue")
  },
  {
    name: "ats-employeur-localisations",
    path: "/ats/employeur/localisations",
    meta: localisationsaLupqay8NRMeta || {},
    component: () => import("/vercel/path0/pages/ats/employeur/localisations.vue")
  },
  {
    name: "ats-employeur-modeles",
    path: "/ats/employeur/modeles",
    meta: modelesKFDdq9LpKfMeta || {},
    component: () => import("/vercel/path0/pages/ats/employeur/modeles.vue")
  },
  {
    name: "ats-employeur-organisation",
    path: "/ats/employeur/organisation",
    meta: organisationOrykJFRTq0Meta || {},
    component: () => import("/vercel/path0/pages/ats/employeur/organisation.vue")
  },
  {
    name: "ats-employeur-page-emploi",
    path: "/ats/employeur/page-emploi",
    meta: page_45emploi5nD8SxKNeDMeta || {},
    component: () => import("/vercel/path0/pages/ats/employeur/page-emploi.vue")
  },
  {
    name: "ats-employeur-presentateurs",
    path: "/ats/employeur/presentateurs",
    meta: presentateursWfh0OflnbNMeta || {},
    component: () => import("/vercel/path0/pages/ats/employeur/presentateurs.vue")
  },
  {
    name: "ats-employeur-rh",
    path: "/ats/employeur/rh",
    meta: rh1WqcXZwlOtMeta || {},
    component: () => import("/vercel/path0/pages/ats/employeur/rh.vue")
  },
  {
    name: "ats",
    path: "/ats",
    meta: indexGI2VqlXZIeMeta || {},
    component: () => import("/vercel/path0/pages/ats/index.vue")
  },
  {
    name: "ats-offres-id",
    path: "/ats/offres/:id()",
    meta: indexRtPCLYamA1Meta || {},
    component: () => import("/vercel/path0/pages/ats/offres/[id]/index.vue")
  },
  {
    name: "ats-offres-id-modification",
    path: "/ats/offres/:id()/modification",
    meta: modification4XkgpcMAhNMeta || {},
    component: () => import("/vercel/path0/pages/ats/offres/[id]/modification.vue")
  },
  {
    name: "ats-offres",
    path: "/ats/offres",
    meta: indexxQAvVcSG5aMeta || {},
    component: () => import("/vercel/path0/pages/ats/offres/index.vue")
  },
  {
    name: "ats-statistiques",
    path: "/ats/statistiques",
    meta: indexQntFY4BMcEMeta || {},
    component: () => import("/vercel/path0/pages/ats/statistiques/index.vue")
  },
  {
    name: "career-slug",
    path: "/career/:slug()",
    meta: indexLoM8BeFJ65Meta || {},
    component: () => import("/vercel/path0/pages/career/[slug]/index.vue")
  },
  {
    name: "career-slug-jobs-job",
    path: "/career/:slug()/jobs/:job()",
    meta: _91job_93IzLOehWAhVMeta || {},
    component: () => import("/vercel/path0/pages/career/[slug]/jobs/[job].vue")
  },
  {
    name: "career-slug-jobs",
    path: "/career/:slug()/jobs",
    meta: indexOmFKZXSFUSMeta || {},
    component: () => import("/vercel/path0/pages/career/[slug]/jobs/index.vue")
  },
  {
    name: "conditions-generales",
    path: "/conditions-generales",
    component: () => import("/vercel/path0/pages/conditions-generales.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "employeurs-slug",
    path: "/employeurs/:slug()",
    component: () => import("/vercel/path0/pages/employeurs/[slug].vue")
  },
  {
    name: "employeurs",
    path: "/employeurs",
    component: () => import("/vercel/path0/pages/employeurs/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "jobs-slug",
    path: "/jobs/:slug()",
    component: () => import("/vercel/path0/pages/jobs/[slug]/index.vue")
  },
  {
    name: "jobs-slug-vignette",
    path: "/jobs/:slug()/vignette",
    meta: vignettemGfW2C4O2BMeta || {},
    component: () => import("/vercel/path0/pages/jobs/[slug]/vignette.vue")
  },
  {
    name: "jobs",
    path: "/jobs",
    component: () => import("/vercel/path0/pages/jobs/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceLjk4bqrWMWMeta || {},
    component: () => import("/vercel/path0/pages/maintenance.vue")
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/vercel/path0/pages/mentions-legales.vue")
  },
  {
    name: "mon-profil-alertes-emploi",
    path: "/mon-profil/alertes-emploi",
    meta: alertes_45emploijRPIfzCOLcMeta || {},
    component: () => import("/vercel/path0/pages/mon-profil/alertes-emploi.vue")
  },
  {
    name: "mon-profil-candidatures",
    path: "/mon-profil/candidatures",
    meta: candidaturesogiKRmDL03Meta || {},
    component: () => import("/vercel/path0/pages/mon-profil/candidatures.vue")
  },
  {
    name: "mon-profil-compte",
    path: "/mon-profil/compte",
    meta: compteSO9ROrg1XSMeta || {},
    component: () => import("/vercel/path0/pages/mon-profil/compte.vue")
  },
  {
    name: "mon-profil-favoris",
    path: "/mon-profil/favoris",
    meta: favoris7F0ojKUlpiMeta || {},
    component: () => import("/vercel/path0/pages/mon-profil/favoris.vue")
  },
  {
    name: "mon-profil",
    path: "/mon-profil",
    meta: indexvrfasLhe06Meta || {},
    component: () => import("/vercel/path0/pages/mon-profil/index.vue")
  },
  {
    name: "mon-profil-informations",
    path: "/mon-profil/informations",
    meta: informationsbm8gBvMltfMeta || {},
    component: () => import("/vercel/path0/pages/mon-profil/informations.vue")
  },
  {
    name: "mon-profil-notifications",
    path: "/mon-profil/notifications",
    meta: notificationsSauKZZuWeNMeta || {},
    component: () => import("/vercel/path0/pages/mon-profil/notifications.vue")
  },
  {
    name: "mon-profil-signature",
    path: "/mon-profil/signature",
    meta: signaturegLeMEO32HwMeta || {},
    component: () => import("/vercel/path0/pages/mon-profil/signature.vue")
  },
  {
    name: "nos-partenaires",
    path: "/nos-partenaires",
    component: () => import("/vercel/path0/pages/nos-partenaires.vue")
  },
  {
    name: "nos-services",
    path: "/nos-services",
    component: () => import("/vercel/path0/pages/nos-services.vue")
  },
  {
    name: "politique-de-donnees",
    path: "/politique-de-donnees",
    component: () => import("/vercel/path0/pages/politique-de-donnees.vue")
  },
  {
    name: "presentation-ats",
    path: "/presentation-ats",
    meta: presentation_45atsQUoZQnDpxBMeta || {},
    component: () => import("/vercel/path0/pages/presentation-ats.vue")
  }
]