import { ref } from 'vue';

const state = ref({
    isOpen: false,
    title: '',
    description: '',
    yesButtonText: '',
    noButtonText: '',
    confirmAction: null,
    cancelAction: null
});

export function useConfirmationBox() {
    function openConfirmation({ newTitle, newDescription, newYesButton, newNoButton, onConfirm, onCancel }) {
        state.value.title = newTitle || 'Confirmation nécessaire';
        state.value.description = newDescription || 'Êtes-vous sûr de vouloir faire cela ?';
        state.value.yesButtonText = newYesButton || 'Oui';
        state.value.noButtonText = newNoButton || 'Non';
        state.value.confirmAction = onConfirm;
        state.value.cancelAction = onCancel;
        state.value.isOpen = true;
    }

    function closeConfirmation() {
        state.value.isOpen = false;
    }

    function confirm() {
        if (state.value.confirmAction) {
            state.value.confirmAction();
        }
        closeConfirmation();
    }

    function cancel() {
        if (state.value.cancelAction) {
            state.value.cancelAction();
        }
        closeConfirmation();
    }

    return {
        state,
        openConfirmation,
        close,
        confirm,
        cancel
    };
}
